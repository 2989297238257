import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../api/useAuthContext';
import { ForceForm } from '../../components/force-form/ForceForm';
import { ChatLoader } from '../../components/chat/chat-loader';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import './ForcePage.scss';

export const ForcePage = () => {
  const { t } = useTranslation();
  const { loadingUser, userIsNotSetup } = useAuthContext();
 
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isPreviouslySubmitted, setIsPreviouslySubmitted] = useState(false);

  const handlePreviousSubmission = (wasSubmitted: boolean) => {
    setIsPreviouslySubmitted(wasSubmitted);
    if (wasSubmitted) {
      setTimeout(() => setShouldRedirect(true), 2000);
    }
  };

  if (loadingUser) {
    return <ChatLoader message={t('common.loading', 'Loading...')} />;
  }

  if (userIsNotSetup) {
    return <Navigate to="/setup" replace />;
  }

  if (shouldRedirect) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="settings-container">
      {!isPreviouslySubmitted && (
        <div className="settings-header-section">
          <h4 
            className="settings-header" 
            dangerouslySetInnerHTML={{ __html: t('force_form.title') }}
          />
        </div>
      )}
      <div className="settings-content">
        <ForceForm 
          onSubmit={(isCancelled) => {
            if (isCancelled) {
              setShouldRedirect(true);
            } else {
              setTimeout(() => setShouldRedirect(true), 2000);
            }
          }}
          onPreviousSubmission={handlePreviousSubmission}
        />
      </div>
    </div>
  );
};
