import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import NoPage from './pages/NoPage';
import { ChatWindow } from './pages/chat/ChatWindow';
import AppLayout from './pages/app-layout/AppLayout';
import { AppStateProvider } from './state/AppProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { ApiProvider } from './api/ApiProvider';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { MessageHandlerProvider } from './state/MessageHandlerProvider';
import { useEffect, useState } from 'react';
import { AuthProvider } from './api/AuthProvider';
import { SettingsPage } from './pages/settings/Settings';
import { ProductHandlerProvider } from './state/ProductHandlerProvider';
import { AccountSetupPage } from './pages/account-setup';
import { InformationPage } from './pages/information/Information';
import { ForcePage } from './pages/force-form/ForcePage';


const reactPlugin = new ReactPlugin();
const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    customDataPrefix: 'data-metrics-',
  },
};

const appInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env.VITE_APPINSIGHTS_CONNECTION_STRING,
    autoTrackPageVisitTime: true,
    distributedTracingMode: 2, // DistributedTracingModes.W3C
    enableCorsCorrelation: true,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
});
appInsights.loadAppInsights();

export default function App() {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppStateProvider>
          <AuthProvider>
            <ApiProvider>
              <ProductHandlerProvider>
                <MessageHandlerProvider>
                  <BrowserRouter>
                    <Routes>
                      <Route path='/' element={<AppLayout />}>
                        <Route index element={<ChatWindow />} />
                        <Route
                          path='chat/:conversationId'
                          element={<ChatWindow />}
                        />
                        <Route path='settings' element={<SettingsPage />} />
                        <Route path='information' element={<InformationPage />} />
                        <Route path='*' element={<NoPage />} />
                        <Route path="/force-form" element={<ForcePage />} />
                      </Route>
                      <Route
                        path='/account-setup'
                        element={<AccountSetupPage />}
                      />
                    </Routes>
                  </BrowserRouter>
                </MessageHandlerProvider>
              </ProductHandlerProvider>
            </ApiProvider>
          </AuthProvider>
        </AppStateProvider>
      </AppInsightsContext.Provider>
    </MsalAuthenticationTemplate>
  );
}

export const AppWithAuthentication = ({
  msalInstance,
}: {
  msalInstance: PublicClientApplication;
}) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    msalInstance.initialize().then(() => {
      setIsInitialized(true);
    });
  }, [msalInstance]);

  return (
    isInitialized && (
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    )
  );
};
