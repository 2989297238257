export enum SurveyId {
    FORM1A = 'form1a',
    FORM1B = 'form1b'
  }
  
  export enum SurveyStatus {
    COMPLETED = 'completed',
    DECLINED = 'declined',
  }
  
  export interface SurveyResponse {
    answer01: string;
    answer02: string;
    answer03: string;
    answer04: string;
    answer05?: string;
    answer06?: string;
  }
  
  export interface Survey {
    status: SurveyStatus;
    result: SurveyResponse[];
  }
  
  export interface SurveyDocument extends Survey {
    id: string;
    type: 'survey';
    createdAt: string;
    survey_id: string;
    userId: string;
    businessArea: string;
    country: string;
    companyName: string;
  }
  
  export interface SurveyError {
    status_code: number;
    detail: string;
  }