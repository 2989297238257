import { useState, FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiContext } from '../../api/useApiContext';
import { useAuthContext } from '../../api/useAuthContext';
import { ForceFormSuccess } from './ForceFormSuccess';
import { SurveyId, SurveyStatus } from '../../types/survey';
import './ForceForm.scss';

type FrequencyOption = 'none' | '2x' | '5x' | '8x' | '10x';
type DurationOption = '5min' | '10min' | '15min' | '20min' | '25min' | '30min';

interface ForceFormProps {
  onSubmit: (isCancelled?: boolean) => void;
  onPreviousSubmission: (wasSubmitted: boolean) => void;
}

export const ForceForm = ({ onSubmit, onPreviousSubmission }: ForceFormProps) => {
  const { t } = useTranslation();
  const { submitSurvey, getSurveyStatus } = useApiContext();
  const { userData } = useAuthContext();

  const [formData, setFormData] = useState({
    callsPerWeek: '',
    timePerCall: '',
    searchesPerWeek: '',
    timePerSearch: ''
  });

  const [errors, setErrors] = useState({
    callsPerWeek: false,
    timePerCall: false,
    searchesPerWeek: false,
    timePerSearch: false
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isPreviouslySubmitted, setIsPreviouslySubmitted] = useState(false);

  const frequencyOptions: FrequencyOption[] = ['none', '2x', '5x', '8x', '10x'];
  const durationOptions: DurationOption[] = ['5min', '10min', '15min', '20min', '25min', '30min'];

  useEffect(() => {
    const checkSurveyStatus = async () => {
      if (!userData) return;
      try {
        const hasCompletedSurvey = await getSurveyStatus(SurveyId.FORM1A);
        if (hasCompletedSurvey) {
          setIsPreviouslySubmitted(true);
          onPreviousSubmission(true);
        }
      } catch (error) {
        console.error('Failed to check survey status:', error);
      }
    };
    checkSurveyStatus();
  }, [userData, getSurveyStatus, onPreviousSubmission]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    
    const newErrors = {
      callsPerWeek: !formData.callsPerWeek,
      timePerCall: !formData.timePerCall,
      searchesPerWeek: !formData.searchesPerWeek,
      timePerSearch: !formData.timePerSearch
    };
    
    setErrors(newErrors);
    
    if (!Object.values(newErrors).some(Boolean)) {
      setIsSubmitting(true);
      try {
        const getActualFrequencyValue = (value: string): string => {
          switch(value) {
            case 'none': return '0';
            case '2x': return '2';
            case '5x': return '5';
            case '8x': return '8';
            case '10x': return '10';
            default: return '0';
          }
        };

        const getActualDurationValue = (value: string): string => {
          return value.replace('min', '');
        };
        
        await submitSurvey(SurveyId.FORM1A, {
          status: SurveyStatus.COMPLETED,
          result: [{
            answer01: getActualFrequencyValue(formData.callsPerWeek),
            answer02: getActualDurationValue(formData.timePerCall),
            answer03: getActualFrequencyValue(formData.searchesPerWeek),
            answer04: getActualDurationValue(formData.timePerSearch)
          }]
        });
        
        setSubmitted(true);
        onPreviousSubmission(true);
        setTimeout(() => {
          onSubmit(false);
        }, 2000);
      } catch (error) {
        console.error('Failed to submit form:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };


  const handleCancel = async () => {
    setIsSubmitting(true);
    try {
      await submitSurvey(SurveyId.FORM1A, {
        status: SurveyStatus.DECLINED,
        result: []
      });
      onSubmit(true); 
    } catch (error) {
      console.error('Failed to decline survey:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormComplete = () => {
    return (
      formData.callsPerWeek !== '' &&
      formData.timePerCall !== '' &&
      formData.searchesPerWeek !== '' &&
      formData.timePerSearch !== ''
    );
  };

  if (submitted) {
    return <ForceFormSuccess hideMessage={false} />;
  }

  if (isPreviouslySubmitted) {
    return (
      <div className="force-form">
        <div className="masking-wrapper">
          <div className="request-form-complete">
            <div>
              <h2 className="feedback-panel-form-heading">
                {t('force_form.already_submitted_title')}
              </h2>
              <p className="feedback-panel-complete-message">
                {t('force_form.already_submitted_message')}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="force-form">
      <form onSubmit={handleSubmit}>
        <div className="form-field">
          <label dangerouslySetInnerHTML={{ 
            __html: t('force_form.questions.calls_per_week') 
          }} />
          <div className="choice-list">
            {frequencyOptions.map(option => (
              <div
                key={option}
                className={`choice-box ${formData.callsPerWeek === option ? 'selected' : ''}`}
                onClick={() => setFormData({ ...formData, callsPerWeek: option })}
              >
                <span className="choice-label">{t(`force_form.options.frequency.${option}`)}</span>
              </div>
            ))}
          </div>
          {errors.callsPerWeek && (
            <span className="error-message">{t('force_form.errors.required')}</span>
          )}
        </div>

        <div className="form-field">
          <label dangerouslySetInnerHTML={{ 
            __html: t('force_form.questions.time_per_call') 
          }} />
          <div className="choice-list">
            {durationOptions.map(option => (
              <div
                key={option}
                className={`choice-box ${formData.timePerCall === option ? 'selected' : ''}`}
                onClick={() => setFormData({ ...formData, timePerCall: option })}
              >
                <span className="choice-label">{t(`force_form.options.duration.${option}`)}</span>
              </div>
            ))}
          </div>
          {errors.timePerCall && (
            <span className="error-message">{t('force_form.errors.required')}</span>
          )}
        </div>

        <div className="form-field">
          <label dangerouslySetInnerHTML={{ 
            __html: t('force_form.questions.searches_per_week') 
          }} />
          <div className="choice-list">
            {frequencyOptions.map(option => (
              <div
                key={option}
                className={`choice-box ${formData.searchesPerWeek === option ? 'selected' : ''}`}
                onClick={() => setFormData({ ...formData, searchesPerWeek: option })}
              >
                <span className="choice-label">{t(`force_form.options.frequency.${option}`)}</span>
              </div>
            ))}
          </div>
          {errors.searchesPerWeek && (
            <span className="error-message">{t('force_form.errors.required')}</span>
          )}
        </div>

        <div className="form-field">
          <label dangerouslySetInnerHTML={{ 
            __html: t('force_form.questions.time_per_search') 
          }} />
          <div className="choice-list">
            {durationOptions.map(option => (
              <div
                key={option}
                className={`choice-box ${formData.timePerSearch === option ? 'selected' : ''}`}
                onClick={() => setFormData({ ...formData, timePerSearch: option })}
              >
                <span className="choice-label">{t(`force_form.options.duration.${option}`)}</span>
              </div>
            ))}
          </div>
          {errors.timePerSearch && (
            <span className="error-message">{t('force_form.errors.required')}</span>
          )}
        </div>

        <div className="form-actions">
          <button 
            type="button"
            onClick={handleCancel}
            className="cancel-button"
            disabled={isSubmitting}
          >
            {t('common.cancel')}
          </button>
          <button 
            type="submit" 
            disabled={isSubmitting || !isFormComplete()}
            className={`submit-button ${isSubmitting ? 'loading' : ''} ${!isFormComplete() ? 'disabled' : ''}`}
          >
            {t('common.send')}
          </button>
        </div>
      </form>
    </div>
  );
};